

<!--videos de calificaciones-->
<app-seccion
[titulo]="'Nuestros videos'"
[fondo]="'azul'"
></app-seccion>

<div class="container-fluid">
  <div class="row m-0 d-flex justify-content-center py-5">
    <div class="col-sm-12 col-md-6 col-lg-4 text-center my-2" *ngFor="let tarjeta of listaTarjetasFiltroVideos;let i=index">
      <div class="card shadow rounded p-2 bg-boton" (click)="elegirTarjeta(tarjeta)" >

        <div class="card-body">
          <h3 class="card-title">{{tarjeta.titulo}}</h3>
        </div>

      </div>
    </div>
  </div>

</div>
