<app-seccion [titulo]="'Consultas técnica sobre SINIDE Y RA'" [fondo]="'azul'"></app-seccion>


<div class="container-fluid" *ngIf="tarjetaContacto">
  <div class="row d-flex justify-content-center flex-wrap my-3">
    <div class="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center bg-header-consulta">
      <img src="assets/images/nuevo/logo-jujuy-consultas.png" width="200" class='img-fluid' alt='...'>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <h4>{{tarjetaContacto.nombreInstitucion}} </h4>
      <p *ngIf="tarjetaContacto.area!==''">
        <span class="icon-container"><i class="fas fa-school"></i></span>{{tarjetaContacto.area}}
      </p>
      <p>
        <span class="icon-container"><i class="mx-2 fas fa-map-marker-alt"></i></span> {{tarjetaContacto.direccion}}
      </p>
      <p>
        <span class="icon-container"><i class="mx-2 fas fa-mobile-alt"></i></span> Télefono Celular: {{tarjetaContacto.celular}}
      </p>
      <p>
        <span class="icon-container"><i class="mx-2 fas fa-envelope"></i></span>Email: {{tarjetaContacto.correo}}
      </p>
    </div>
  </div>
</div>
