<app-slider class="d-none d-md-block" [sliderSimple]="false"></app-slider>
<app-slider class="d-block d-md-none" [sliderSimple]="true"></app-slider>
<app-ultimos-videos></app-ultimos-videos>
<app-portada-inicio></app-portada-inicio>
<app-contacto></app-contacto>
<app-seccion
[titulo]="'Sistemas para la carga de información'"
[descripcion]="'Haga clic en el siguiente botón para ingresar a SGE'"
[habilitarBotones]="true"
></app-seccion>

<app-seccion-button *ngIf="false"
[titulo]="'Cuadernillos RA 2024'"
[subtitulo]=""
[descripcion]="'Haga clic en el siguiente botón para ver y descargar los cuadernillos RA 2024'"
[habilitarBoton]="true"
[tituloBoton]="'Ver cuadernillos'"
[urlBoton]="'documentacion'"
></app-seccion-button>

<!--<app-noticias-importantes></app-noticias-importantes>-->
<app-seccion-tarjetas-filtros-videos></app-seccion-tarjetas-filtros-videos>
<!-- <app-seccion-videos-calificaciones></app-seccion-videos-calificaciones> -->
<app-nuestros-ultimos-videos></app-nuestros-ultimos-videos>
<app-nuestros-ultimas-capacitaciones></app-nuestros-ultimas-capacitaciones>
<!-- <app-contacto></app-contacto> -->
<app-como-llegar></app-como-llegar>

<!--
<app-seccion-videos-principales></app-seccion-videos-principales>
<app-carrusel [sliderSimple]="false"></app-carrusel>
<app-carrusel [sliderSimple]="true"></app-carrusel>
<app-ultimos-videos></app-ultimos-videos> -->



<!-- Smartsupp Live Chat script -->
<div class="container">

  <app-form-reactivo *ngIf="false"></app-form-reactivo>
</div>


<ng-template #templateFormAula>
  <div class="modal-dialog modal-md m-0" tabindex="-1" role="document">
    <div class="modal-content">
      <div class="modal-header headerModal text-white">
        <h5 class="modal-title" id="exampleModalLabel">Formulario de Consulta</h5>

        <button type="button" class="btn btn-danger btn-circle pull-right" (click)="decline()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <!--formulario-->
        <div class="alert alert-primary shadow rounded" role="alert">
          Al completar el formulario nos pondremos en contacto con usted para solucionar su inconveniente.
        </div>
        <form #form="ngForm" class="needs-validation" novalidate [formGroup]="contactForm"
          (ngSubmit)="onSubmit(contactForm)" method="post" name="formularioDocente">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Apellido y nombre</label>
              <input type="text" [ngClass]="getClassInput(nombre)" formControlName="nombre" name="nombre"
                class="form-control" required>
              <div class="invalid-feedback" *ngIf="contactForm.get('nombre').errors?.required">
                <!--!! lo convierte a boolean-->
                El apellido y nombre es requerido
              </div>
              <div class="invalid-feedback" *ngIf="nombre.errors?.minlength">
                Debe ingresar su apellido y nombre completo
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="inputEmail4">Nombre de la escuela</label>
            <input type="text" [ngClass]="getClassInput(institucion)" formControlName="institucion" name="institucion"
              class="form-control" required>
            <div class="invalid-feedback" *ngIf="contactForm.get('institucion').errors?.required">
              <!--!! lo convierte a boolean-->
              El nombre de la escuela es requerido
            </div>
            <div class="invalid-feedback" *ngIf="nombre.errors?.minlength">
              Debe ingresar el nombre completo de la escuela
            </div>
          </div>


          <div class="form-row">

            <div class="form-group col-md-12">
              <label for="inputEmail4">Celular:</label>
              <input type="text" formControlName="celular" [ngClass]="getClassInput(celular)" required name="celular"
                class="form-control">
              <div class="invalid-feedback" *ngIf="celular.errors?.required">
                El celular es requerido para comunicarnos con usted
              </div>
              <div class="invalid-feedback" *ngIf="celular.errors?.minlength">
                Ingrese todos los numeros del celular
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">Describe el problema</label>
            <textarea class="form-control" formControlName="consulta" [ngClass]="getClassSelect(consulta)"
              name="consulta" rows="4" placeholder="Escriba aquí su consulta......"></textarea>
            <div class="invalid-feedback" *ngIf="consulta.errors?.required">
              Su consulta es requerida
            </div>
          </div>
          <div class="form-group text-center">
            <button type="button" class="btn btn-danger mx-2" data-dismiss="modal"
              (click)="cancelar()">Cancelar</button>
            <button type="submit" class="btn botonesInicio mx-2"
              [disabled]="!nombre.valid || !institucion.valid || !celular.valid || !consulta.valid">Enviar
              Consulta</button>

          </div>
        </form>
        <!--fin formulario-->
      </div>

    </div>
  </div>
</ng-template>

<ng-template #templateNovedad>
  <div class=" m-0" tabindex="-1" role="document">
    <div class="modal-content">
      <div class="modal-header text-white" [ngClass]="getClass(noticiaElegida.id)">
        <h4 class="modal-title" id="exampleModalLabel"><i class="far fa-bell"></i> {{noticiaElegida.titulo}}</h4>

        <button type="button" class="btn btn-danger btn-circle pull-right" (click)="decline()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert text-white shadow rounded" [ngClass]="getClass(noticiaElegida.id)" role="alert">
          {{noticiaElegida.descripcion}}
        </div>
        <!--formulario-->

      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateInfoSiu>

  <div class="modal-header headerModal rounded text-white text-center">
    <h4 class="modal-title w-100 text-center">Materias y Exámenes SIU</h4>
    <button type="button" class="btn btn-danger btn-circle pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-dark">
    <div class="text-center d-sm-block d-md-block d-lg-block">
      <div class="alert alert-primary shadow rounded" role="alert">
        Por consultas de inscripciones a las materias y exámenes podrá consultar a los siguientes contactos por unidad
        académica.
      </div>
      <div class="form-group">
        <label for="institucion">Facultad o Escuela: </label>
        <select (change)="cambios()" class="custom-select" [(ngModel)]="facultadSiu" name="institucion" id="institucion"
          required>
          <option [value]="f" [selected]="i===0" [disabled]="i===0" *ngFor="let f of listaFacultades; let i=index">{{f}}
          </option>
        </select>
      </div>
      <div *ngIf="siuElegido">
        <div class="alert alert-primary shadow rounded" role="alert">
          <p> Contacto/s: {{siuElegido.contacto}}</p>
        </div>
        <div class="alert alert-success shadow rounded" role="alert">
          <p>Correo/s: {{siuElegido.correo}}</p>
        </div>

        <a *ngIf="siuElegido.url !== ''" href="{{siuElegido.url}}" target="_blank"
          class="btn btn-primary shadow rounded">Visitar página SIU {{siuElegido.institucion}}</a>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #templateUsuarios>
  <div class="modal-dialog modal-md m-0" tabindex="-1" role="document">
    <div class="modal-content">
      <div class="modal-header headerModal text-white">
        <h5 class="modal-title" id="exampleModalLabel">Usuarios y contraseñas</h5>

        <button type="button" class="btn btn-danger btn-circle pull-right" (click)="decline()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <!--formulario-->
        <div class="form-group col-md-12">
          <label for="inconveniente">Seleccione su inconveniente </label>
          <select class="custom-select" [(ngModel)]="problemaUsuario" name="inconveniente" required>
            <option [value]="f" [selected]="i===0" [disabled]="i===0"
              *ngFor="let f of listaProblemasUsuarios; let i=index">{{f}}</option>
          </select>
        </div>
        <form *ngIf="problemaUsuario !== 'seleccione'" class="was-validated" #formUsu="ngForm"
          (ngSubmit)="onSubmitVar(formUsu)" method="post" name="formularioDocente">
          <div class="form-row">

            <div class="form-group col-md-12" *ngIf="problemaUsuario !== listaProblemasUsuarios[3]">
              <label for="inputEmail4">Apellido y nombre</label>
              <input type="text" [(ngModel)]="contacto.nombre" #nombre="ngModel" name="nombre" class="form-control"
                required>
              <div class="invalid-feedback" *ngIf="!!nombre.errors?.required">
                <!--!! lo convierte a boolean-->
                El apellido y nombre es requerido
              </div>
            </div>


            <div class="form-group col-md-12" *ngIf="problemaUsuario !== listaProblemasUsuarios[3]">
              <label for="inputPassword4">Email Actual en UNJu Virtual</label>
              <input type="email" [(ngModel)]="contacto.email" #email="ngModel" name="email" class="form-control"
                required>
              <div class="invalid-feedback" *ngIf="!!email.errors?.required">
                El Email Actual es requerido (bien escrito)
              </div>
              <div class="invalid-feedback" *ngIf="!emailPattern.test(contacto.email) && email.touched">
                Ingrese un correo valido
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="problemaUsuario === listaProblemasUsuarios[1]">
              <label for="inputPassword4">Email Nuevo que utilizara en adelante</label>
              <input type="email" [(ngModel)]="contacto.emailNuevo" #emailNew="ngModel" name="emailNuevo"
                class="form-control" required>
              <div class="invalid-feedback" *ngIf="!!emailNew.errors?.required">
                El Email NUEVO es requerido (bien escrito)
              </div>
              <div class="invalid-feedback" *ngIf="!emailPattern.test(contacto.emailNuevo) && emailNew.touched">
                Ingrese un correo valido
              </div>
            </div>

            <div class="form-group col-md-12" *ngIf="problemaUsuario !== listaProblemasUsuarios[3]">
              <label for="inputPassword4">DNI</label>
              <input type="number" [(ngModel)]="contacto.dni" #dni="ngModel" name="dni" min="10000000" max="50000000"
                maxlength="8" minlength="8" class="form-control" required>
              <div class="invalid-feedback" *ngIf="!!dni.errors?.required">
                El dni es requerido
              </div>
              <div class="invalid-feedback" *ngIf="!dni.errors?.max || !dni.errors?.min">
                El dni debe ser de 8 digitos
              </div>

            </div>
            <div class="form-group col-md-12" *ngIf="problemaUsuario !== listaProblemasUsuarios[3]">
              <label for="inputPassword4">Usted es:</label>
              <select class="custom-select" #rol="ngModel" [(ngModel)]="contacto.rol" name="rol" required>
                <option selected value="Profesor">Profesor</option>
                <option value="Estudiante">Estudiante</option>
              </select>
              <div class="invalid-feedback" *ngIf="rol.errors?.required">
                Debe seleccionar un rol
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="problemaUsuario == listaProblemasUsuarios[1]">
              <label for="institucion">Facultad o Escuela a la que pertenece: </label>
              <select class="custom-select" #facultad="ngModel" [(ngModel)]="contacto.institucion"
                [ngClass]="getClassSelectVar(facultad)" name="institucion" id="institucion" required>
                <option [value]="f" [selected]="i===0" [disabled]="i===0"
                  *ngFor="let f of listaFacultades; let i=index">{{f}}</option>
              </select>
              <div class="invalid-feedback" *ngIf="facultad.errors?.required">
                Debe seleccionar la institución
              </div>
              <div class="invalid-feedback" *ngIf="validarSelect(facultad.value)">
                Debe seleccionar la institución
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="problemaUsuario == listaProblemasUsuarios[1]">
              <label for="exampleFormControlTextarea1">Carrera:</label>
              <input type="text" [(ngModel)]="contacto.carrera" #carrera="ngModel" name="carrera" class="form-control"
                required>
              <div class="invalid-feedback" *ngIf="!!carrera.errors?.required">
                La carrera es requerida
              </div>
            </div>



          </div>

          <div class="form-group text-center" *ngIf="problemaUsuario !== listaProblemasUsuarios[3]">
            <button type="button" class="btn btn-danger mx-2" data-dismiss="modal"
              (click)="cancelar()">Cancelar</button>
            <button type="submit" class="btn botonesInicio mx-2"
              [disabled]="formUsu?.invalid || validarSelect(contacto.institucion)">Enviar Consulta</button>

          </div>
        </form>

        <div class="alert alert-primary" role="alert" *ngIf="problemaUsuario === listaProblemasUsuarios[3]">
          <p>VERIFIQUE el spam o el correo no deseado en notificaciones</p>
        </div>

      </div>

    </div>
  </div>

</ng-template>
