export const tarjetasFiltrosVideos: any[] = [
  {
    id:1,
    titulo:'Promover',
    alias:'promover',
    descripcion: null
  },
  {
    id:1,
    titulo:'Roles y permisos',
    alias:'roles_permisos',
    descripcion: null
  },
  {
    id:2,
    titulo:'Inscripciones',
    alias:'inscripciones',
    descripcion: null
  },
  {
    id:3,
    titulo:'Pases',
    alias:'pases',
    descripcion: null
  },
  {
    id:4,
    titulo:'Notas',
    alias:'notas',
    descripcion: null
  },
  {
    id:4,
    titulo:'Peso y Talla',
    alias:'peso_talla',
    descripcion: null
  },
  {
    id:5,
    titulo:'Constancia',
    alias:'constancia',
    descripcion: null
  },
  {
    id:6,
    titulo:'Tablero',
    alias:'tablero',
    descripcion: null
  },
  {
    id:7,
    titulo:'Abanderado',
    alias:'abanderado',
    descripcion: null
  },
  {
    id:12,
    titulo:'Todos',
    alias:'todos',
    descripcion: null
  },
]
