import { Component, Input, OnInit } from '@angular/core';
import { sistemas } from 'src/app/data/data';

@Component({
  selector: 'app-seccion-button',
  templateUrl: './seccion-button.component.html',
  styleUrls: ['./seccion-button.component.scss']
})
export class SeccionButtonComponent implements OnInit {

  @Input() titulo:string ='';
  @Input() subtitulo:string ='';
  @Input() descripcion:string = null;
  @Input() habilitarBoton: boolean = false;
  @Input() urlBoton: string = null;
  @Input() tituloBoton: string = null;
  @Input() imagenBotton: boolean = null;
  @Input() fondo:string = 'naranja';
  @Input() habilitarBtnHref: boolean = false;
   listaSistemas: any[]=sistemas;

  constructor() { }

  ngOnInit(): void {
  }
  getClass():string {
    return this.fondo == 'naranja' ? 'bg-naranja' : 'bg-azul';
  }

}
