import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RecursosComponent } from './dashboard/recursos/recursos.component';
import { ActividadesComponent } from './dashboard/actividades/actividades.component';
import { PagesComponent } from './dashboard/pages/pages.component';
import { PageAcordionComponent } from './dashboard/page-acordion/page-acordion.component';
import { DocumentacionComponent } from './dashboard/documentacion/documentacion.component';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { ConsultoresComponent } from './components/consultores/consultores.component';
import { ConsultoresEscuelasComponent } from './consultores-escuelas/consultores-escuelas/consultores-escuelas.component';
import { FormComponent } from './dashboard/form/form.component';
import { FormRaComponent } from './dashboard/form-ra/form-ra.component';
import { GaleriaComponent } from './dashboard/galeria/galeria.component';
import { QuienesSomosComponent } from './dashboard/quienes-somos/quienes-somos.component';
import { VideosPrincipalesComponent } from './videos-principales/videos-principales/videos-principales.component';
import { FormulariosComponent } from './formularios/formularios/formularios.component';
import { EvaluarmeComponent } from './home/evaluarme/evaluarme.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  // redirectTo: 'home', //poner login
  // pathMatch: 'full',
  { path: 'home', component: HomeComponent},
  { path: 'seccion1/:page', component: RecursosComponent},
  { path: 'seccion2/:page', component: ActividadesComponent},
  { path: 'seccion/:pages', component: PagesComponent},
  { path: 'page/:acordion', component: PageAcordionComponent},
  { path: 'documentacion', component: FormulariosComponent },
  { path: 'evaluarme', component: EvaluarmeComponent },
  { path: 'consultores', component: ConsultoresEscuelasComponent },
  { path: 'formulario', component: FormComponent },
  { path: 'videos-tutoriales', component: VideosPrincipalesComponent },
  { path: 'formulariora', component: FormRaComponent },
  { path: 'galeria', component: GaleriaComponent},
  { path: 'quienessomos', component: QuienesSomosComponent},
  { path: 'not-found', component: NotFoundComponent },

  {
    path: '**',
    redirectTo: 'not-found'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
