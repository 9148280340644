<app-slider class="d-none d-md-block" [sliderSimple]="false"></app-slider>
<app-slider class="d-block d-md-none" [sliderSimple]="true"></app-slider>

<app-seccion [titulo]="'Formularios'"
  [descripcion]="'En esta sección podrás ver y descargar formularios para el sistema SGE'"
  [fondo]="'naranja'"></app-seccion>

<div class="container-fluid  my-2 " style=" background-color: white;">
  <!-- <div class="row m-0">
    <app-formularios-sge [listaDocumentos]="listaDocumentos"></app-formularios-sge>
  </div> -->
  <tabset class="text-center nav-fill" type="pills" style="width: 100%; justify-content: center; padding: 5px;">

    <tab class="tabb" heading="Cuadernillos RA - 2024" *ngIf="!true">
      <app-formularios-ra [listaDocumentos]="listaDocumentosRa"></app-formularios-ra>
    </tab>
    <tab class="tabb" heading="Formularios SGE" *ngIf="true">

      <app-formularios-sge [listaDocumentos]="listaDocumentos"></app-formularios-sge>
    </tab>
  </tabset>
</div>



<ng-template #templateDocumentacion>

  <div class="modal-header azul-violeta text-white header-card text-center">
    <h2 class="modal-title  w-100 text-center">{{documentoElegido.titulo}}</h2>
    <button *ngIf="false" type="button" class="btn btn-primary btn-circle pull-right mx-1 border border-white"
      aria-label="Close">
      <i class="fas fa-chevron-circle-left"></i>
    </button>
    <button *ngIf="false" type="button" class="btn btn-primary btn-circle pull-right mx-1 border border-white"
      aria-label="Close">
      <i class="fas fa-chevron-circle-right"></i>
    </button>
    <button type="button" class="btn btn-danger btn-circle pull-right border border-white" aria-label="Close"
      (click)="modalRef.hide()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-dark">
    <div class="text-center d-sm-block d-md-block d-lg-block" id="videoSeleccionado">
      <a class='btn btn-danger col-6 my-1' href="{{getUrlPdfView(documentoElegido.urlpdf)}}" target="_blanck">
        Descargar <i class="fas fa-external-link-alt"></i> </a>

      <div class="embed-responsive embed-responsive-16by9">
        <iframe width="100%" height="500" [src]="getUrlPdfPreview(documentoElegido.urlpdf)  | safeUrl"></iframe>
      </div>
    </div>
  </div>

</ng-template>
