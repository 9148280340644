import { Component, Input, OnInit } from '@angular/core';
import { Documento } from 'src/app/models/Documento';
import { listaDocumentos } from './data';

@Component({
  selector: 'app-evaluarme',
  templateUrl: './evaluarme.component.html',
  styleUrls: ['./evaluarme.component.scss']
})
export class EvaluarmeComponent implements OnInit {
  @Input() listaDocumentos: Documento[];
  documentoElegido: Documento = new Documento();

  constructor() {
    this.listaDocumentos = listaDocumentos;
  }

  ngOnInit(): void {
  }

  getUrlPdfView(urlpdf: string): string{
    let pos = urlpdf.indexOf('?usp=sharing');
    return urlpdf.slice(0,pos);
  }

  getUrlPdfPreview(urlpdf: string): string{
    let pos = urlpdf.indexOf('view?usp=sharing');
    return urlpdf.slice(0,pos)+'preview';
  }
}
