<app-seccion-button
[titulo]="'EVALUAR-ME 2024'"
[descripcion]="'Haga clic en el siguiente botón para ingresar'"
[urlBoton]="'https://monitoreosinidesge.jujuy.edu.ar/#/evaluarme'"
[habilitarBtnHref]="true"
[tituloBoton]="'Ingresar a EVALUAR-ME'"
></app-seccion-button>


<div class="mc-banner-type-c-personas img-fluid mb-4 pb-4">
  <div class="container-fluid text-center my-2">
    <div>

      <div class="container-fluid  d-flex justify-content-center flex-wrap my-1">
        <div *ngFor="let d of listaDocumentos; let i=index" class="card  p-0 col-lg-6 my-2 cards text-center ">
          <div class='card-header bg-azul'>
            <h4 class='card-title text-white col-12'>{{d.titulo}} </h4>
          </div>
          <div class='card-body px-0'>
            <!--<div class="badge-warning rounded mx-5">Nuevo</div>-->
            <div class="embed-responsive embed-responsive-16by9">
              <iframe width="100%" [src]="getUrlPdfPreview(d.urlpdf)  | safeUrl"></iframe>
            </div>
          </div>
          <div class='card-footer card-footer-button text-muted mx-auto'>
            <a class='btn botondes btn-dark col-12' href="{{getUrlPdfView(d.urlpdf)}}" target="_blanck">
              Descargar <i class="fas fa-external-link-alt"></i> </a>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
