<app-seccion [titulo]="titulo" [fondo]="'azul'"></app-seccion>

<div [ngClass]="getClass()"
  class=" row py-4 m-0 d-flex justify-content-center flex-wrap text-white text-center rounded shadow">
  <div *ngIf="subtitulo" class="col-sm-12 col-md-6 col-lg-12">
    <h1>{{subtitulo}}</h1>
  </div>

  <div class="col-sm-12 col-md-6 col-lg-6 justify-content-center align-items-center text-center flex-wrap">
    <h4 *ngIf="descripcion"> {{descripcion}}</h4>
    <div *ngIf="habilitarBoton">
      <a *ngIf="urlBoton" [routerLink]="[urlBoton]"
        class='btn botonesInicio  p-2 m-2 col-sm-12 col-md-5 col-lg-6 texto-btn'>
        <img *ngIf="imagenBotton" src="assets/images/{{imagenBotton}}" width="200px" height="4px" class="sge img-fluid"
          alt='...'>
        {{tituloBoton}}
      </a>
    </div>
    <div *ngIf="habilitarBtnHref">
      <a *ngIf="urlBoton" href="{{urlBoton}}" target="_blank"
        class='btn botonesInicio  p-2 m-2 col-sm-12 col-md-5 col-lg-6 texto-btn'>
        <img *ngIf="imagenBotton" src="assets/images/{{imagenBotton}}" width="200px" height="4px" class="sge img-fluid"
          alt='...'>
        {{tituloBoton}}
      </a>
    </div>

  </div>
</div>
