<div class="wave-animation">
	<div class="wave">
  </div>
</div>

<div class="container pt-0 pb-2">

  <div class="row m-0 py-3 d-flex justify-content-center align-items-center flex-wrap">
    <div class="col-sm-12 col-md-6 col-lg-6 text-center">
      <img src="assets/images/nuevo/logo_jujuy_con_la_gente.png" width="300" class="img-fluid" alt="logo">
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 text-md-start text-lg-left text-sm-center ">
      <h2 class="titulo-me">Ministerio de Educación</h2>
      <h3 class="titulo-me font-weight-bold">Secretaria de Planeamiento Estratégico</h3>
      <h3 class="titulo-me"> Área sistema de información </h3>
    </div>
  </div>
</div>
