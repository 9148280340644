import { Component, Input, OnInit } from '@angular/core';
import { videos } from '../../data/videos-sge';
import { Video } from '../../data/video';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-videos-principales',
  templateUrl: './videos-principales.component.html',
  styleUrls: ['./videos-principales.component.scss']
})
export class VideosPrincipalesComponent implements OnInit {
  @Input() videoElegido: any;
  listaVideosPrincipales = videos;
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  constructor(private route: ActivatedRoute) {

    console.log(this.listaVideosPrincipales)
   }
   alias: string | null = null;
  ngOnInit(): void {
       // Suscribirse a los parámetros de la ruta
       this.route.params.subscribe(params => {
        this.alias = params['alias']; // Captura el parámetro 'alias'
        console.log('Alias recibido:', this.alias);
        //filtarmos los videos principales por categoria en mayus
        if(this.alias !== 'todos'){
          this.listaVideosPrincipales = this.listaVideosPrincipales.filter(v=> v.categoria.toUpperCase().includes(this.alias?.toUpperCase()))
        }
      });
  }
  getClass(video: Video): string {
    return video.id === this.videoElegido.id ? 'bg-dark text-white shadow rounded' : '';
  }

  getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }
  getUrlVideo(urlVideo: string):string{
    return "https://youtu.be/"+urlVideo.slice(30,urlVideo.length);

  }
}

